/* SEARCH-TAG: page-template  */
import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import styled from 'styled-components';
import supportPostProps from 'TMPropTypes/supportPostProps';
import HeadTags from 'TMComponents/HeadTags';
import Main from 'TMComponents/Layout/Main';
import Container from 'TMComponents/Container';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { breakpoints } from 'TMConstants';
import SupportCategoryList from 'TMComponents/SupportCategoryList';
import { H1 } from 'TMComponents/Typography';
import Canonical from 'TMComponents/Canonical';
import isServerSideRender from 'TMUtils/isServerSideRender';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { BannerContext } from 'TMComponents/Banner';
import { LayoutContext } from 'TMComponents/Layout';

const Body = styled(Container)`
    max-width: 1280px;
    padding: 0 16px;
`;

const ContentWrapper = styled(FlexWrapper)`
    flex-wrap: wrap;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        flex-wrap: nowrap;
    }
`;

const PostWrapper = styled.div`
    padding: 16px 0;
    width: 100%;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
        padding: 32px;
        width: calc(100% - 464px);
        background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    }
`;

const ServerSideRender = isServerSideRender();
const Content = lazy(() => import('TMComponents/Content'));

const SupportPost = ({ data: { post, category } }) => {
    const isBannerAllowed = true;
    const isCrumbsAllowed = true;

    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed,
            crumbList: [
                { title: 'Help & Support', link: '/help-support/' },
                {
                    title: category.name,
                    link: `/help-support/${category.slug}/`
                },
                {
                    title: post.name,
                    link: `/help-support/${category.slug}/${post.slug}/`
                }
            ]
        });
    }, []);

    return (
        <Main
            isBannerAllowed={isBannerAllowed}
            isCrumbsAllowed={isCrumbsAllowed}>
            <Canonical
                href={`help-support/${category.slug}/${post.slug}`}
            />
            <HeadTags tags={post.seoMetaTags.tags} />
            <Body>
                <ContentWrapper align="flex-start">
                    <SupportCategoryList
                        currentCategory={category.slug}
                        currentPost={post.slug}
                    />
                    <PostWrapper>
                        <H1>{post.name}</H1>
                        {!ServerSideRender && (
                            <Suspense fallback={<div />}>
                                <Content
                                    content={post.content}
                                    noSidePadding
                                    isPageContent
                                />
                            </Suspense>
                        )}
                    </PostWrapper>
                </ContentWrapper>
            </Body>
        </Main>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    query ($slug: String!, $categorySlug: String!) {
        post: datoCmsSupportPost(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            name
            nameVariant
            slug
            content {
                ...AppStoreLinkFields
                ...buttonFields
                ...imageFields
                ...lottieFields
                ...separatorFields
                ...tableFields
                ...textFields
                ...videoFields
            }
        }
        category: datoCmsSupportCategory(slug: { eq: $categorySlug }) {
            name
            slug
        }
    }
`;

/**
 * Prop types
 */
SupportPost.propTypes = {
    data: shape({
        supportPost: shape(supportPostProps)
    }).isRequired
};

export default SupportPost;
